import { Box, Chip, Collapse, IconButton, Link, makeStyles, Typography,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
import APIEndpoints from "api/APIEndpoints";
import { useResource } from "hooks";
import { lightFetcher } from "hooks/useResource";
import { useState } from "react";
import moment from "moment";
import { useTranslation } from "i18n";

const useStyles = makeStyles((theme) => ({
  alert: {
    borderRadius: 0,
    backgroundColor: theme.palette.primary.main,
    color: "white",
  },
  chip: {
    marginRight: 8,
    color: "white",
    fontWeight: "bold",
    borderColor: "white",
  },
  message: {
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      textAlign: "center",
    },
    marginLeft: "auto",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const ShowBanner = () => {
  const [open, setOpen] = useState(true);
  const { data } = useResource(
    APIEndpoints.SHOWS.NEXT,
    { fetcher: lightFetcher, options: { errorRetryInterval: 10000 } },
  );
  const classes = useStyles();
  const { t } = useTranslation();
  if (!data) return null;
  return (
    <Collapse in={open}>
      <Alert
        classes={{
          root: classes.alert,
          message: classes.message,
        }}
        severity="info"
        icon={false}
        action={(
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={() => setOpen(false)}
          >
            <Close fontSize="inherit" />
          </IconButton>
        )}
      >
        <Chip size="small" className={classes.chip} label="SHOWS" variant="outlined" />
        {
          moment(data.beginsAt).isBefore(moment())
            ? (
              <Typography>
                {`${data.name} ${t("STARTED")} ${moment(data.beginsAt).fromNow()}`}
              </Typography>
            )
            : (
              <Typography>
                {`${data.name} ${t("WILL_START")} ${moment(data.beginsAt).fromNow()}`}
              </Typography>
            )
        }
        <Box ml={1}>
          <Link
            target="_blank"
            color="inherit"
            href="https://ratherlabs.com/faq/"
          >
            {t("HOW_TO_PARTICIPATE")}
          </Link>

        </Box>
      </Alert>
    </Collapse>
  );
};

export default ShowBanner;
